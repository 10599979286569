$system-color-blue: (
  "blue": (
    5: #eff6fb,
    10: #d9e8f6,
    20: #aacdec,
    30: #73b3e7,
    40: #4f97d1,
    50: #2378c3,
    60: #2c608a,
    70: #274863,
    80: #1f303e,
    90: #11181d,
    "vivid": (
      5: false,
      10: false,
      20: false,
      30: #58b4ff,
      40: #2491ff,
      50: #0076d6,
      60: #005ea2,
      70: #0b4778,
      80: #112f4e,
      90: false
    )
  )
);
